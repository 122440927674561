/* app/ui/event/calendar */

import $ from 'jquery';
import * as Util from 'Util/core';

var selectors = {
	link: '.js-calendar-link'
};

var dataSelectors = {
	startDate: 'calendar-start-date',
	endDate: 'calendar-end-date',
	title: 'calendar-title',
	description: 'calendar-description',
	venue: 'calendar-venue',
	address: 'calendar-address',
	country: 'calendar-country'
};

var Calendar = {
	init: function () {
		Calendar._initEvents();
		Calendar._initLinks();
	},

	_initEvents: function () {
		$(document).on('click', selectors.link, Calendar._linkClick);
	},

	_initLinks: function () {
		var $links = $(selectors.link);

		$links.each(Calendar._initLink);
	},

	_initLink: function (i, el) {
		var $link = $(el);

		var url = Calendar._buildUrl($link);

		$link.attr('href', url);
	},

	_buildUrl: function ($link) {
		var startDate = $link.data(dataSelectors.startDate) || '';
		var endDate = $link.data(dataSelectors.endDate) || '';

		var title = $link.data(dataSelectors.title) || '';
		var description = $link.data(dataSelectors.description) || '';
		var venue = $link.data(dataSelectors.venue) || '';
		var address = $link.data(dataSelectors.address) || '';
		var country = $link.data(dataSelectors.country) || '';

		var location = [];

		var href = 'data:text/calendar;charset=utf8,';

		if (startDate) {
			startDate = Calendar._enforceDateFormat(startDate);
		}
		if (endDate) {
			endDate = Calendar._enforceDateFormat(endDate);
		}

		if (venue) {
			location.push(venue);
		}
		if (address) {
			location.push(address);
		}
		if (country) {
			location.push(country);
		}
		location = location.join(', ');

		href += ([
			'BEGIN:VCALENDAR',
			'VERSION:2.0',
			'BEGIN:VEVENT',
			'URL:' + document.URL,
			'DTSTART:' + startDate,
			'DTEND:' + endDate,
			'SUMMARY:' + title,
			'DESCRIPTION:' + description,
			'LOCATION:' + location,
			'END:VEVENT',
			'END:VCALENDAR'
		]).join('\n');

		$link.attr('href', href);
	},

	_enforceDateFormat: function (dateString) {
		var dateObj = new Date(dateString);

		var iso = dateObj.toISOString();
		// convert to vcal format
		iso = iso.split('.')[0];
		iso = iso.replace(/[\-\:]+/gi, '');
		iso += 'Z';

		return iso;
	},

	_linkClick: function (e) {
		var $link;
		var href;
		var filename;
		var blob;

		if (navigator.msSaveBlob) {
			e.preventDefault();

			$link = $(e.target).closest(selectors.link);
			href = $link.attr('href');
			filename = $link.attr('download');
			blob = Calendar._createBlob(href);

			navigator.msSaveBlob(blob, filename);
		} else {
			// Let the default link action download the blob
		}
	},

	_createBlob: function (dataUrl) {
		var blob;
		var type = dataUrl.replace(/\n/g, '').replace(/^data:(.*?);.*$/, '$1');

		blob = new Blob([dataUrl.replace(/^data:.*?;.*?,/, '')], {
			type: type
		});

		return blob;
	}
};

export { Calendar };
