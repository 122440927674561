const selectors = {
	container: '.js-number-input',
	trigger: '.js-number-input__toggle',
	input: '.js-number-input__input',
	increment: '.js-number-input__toggle[data-value="1"]',
	decrement: '.js-number-input__toggle[data-value="-1"]',
};

let elements = document.querySelectorAll(selectors.container);

let module = {
	init() {
		if (elements.length) {
			module._initEvents();
		}
	},
	_initEvents() {
		for (let i = 0; i < elements.length; i++) {
			let elm = elements[i];
			elm.addEventListener('click', module._clickEvent);
			elm.addEventListener('change', module._changeEvent);
		}
	},
	_clickEvent(event) {
		let $trigger = event.target;
		let isToggle = $trigger.classList.contains(selectors.trigger.substr(1));
		if (isToggle) {
			let $target = $trigger.closest(selectors.container).querySelector(selectors.input);
			let delta = parseInt($trigger.getAttribute('data-value'), 10);
			module._updateTarget($target, delta);
		}
	},
	_changeEvent(event) {
		let $trigger = event.target;
		let isToggle = $trigger.classList.contains(selectors.input.substr(1));
		if (isToggle) {
			let $target = $trigger.closest(selectors.container).querySelector(selectors.input);
			module._updateTarget($target, 0);
		}
	},
	_updateTarget($target, delta) {
		let $container = $target.closest(selectors.container);
		let $increment = $container.querySelector(selectors.increment);
		let $decrement = $container.querySelector(selectors.decrement);
		let minValue = parseInt($target.getAttribute('min'), 10);
		let maxValue = parseInt($target.getAttribute('max'), 10);
		let currentValue = parseInt($target.value, 10);
		let nextValue = currentValue + delta;
		let canDecrement = nextValue > minValue;
		let canIncrement = nextValue < maxValue;

		$decrement.disabled = canDecrement === false;
		$increment.disabled = canIncrement === false;

		if (canIncrement || canDecrement) {
			$target.setAttribute('value', nextValue);
		}
	}
};

let NumberInput = {
	init: module.init
};

export { NumberInput };