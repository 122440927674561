const selectors = {
	container: '.js-tour__container',
	notification: '.js-tour__notification',
	form: '.js-tour__booking-form',
	date_picker: '.js-datepicker-value',
	book_button: '.js-tour__book-button',
	accept_button: '.js-tour__accept',
};

let elements = {
	form: document.querySelectorAll(selectors.form),
	accept_button: document.querySelectorAll(selectors.accept_button),
};

let module = {
	init() {
		if (elements.form.length) {
			module._initEvents();
		}
	},
	_initEvents() {
		for (let i = 0; i < elements.form.length; i++) {
			let form = elements.form[i];
			let elm = form.querySelector(selectors.date_picker);
			if (elm) {
				elm.addEventListener('change', module._changeEvent);
			}
		}
		Array.from(elements.accept_button).forEach(elm => elm.addEventListener('click', module._acceptClickEvent));
	},
	_changeEvent(event) {
		let $container = event.target.closest(selectors.container);
		if ($container) {
			module._updateNotificationDisplay($container);
		}
	},
	_acceptClickEvent(event) {
		let $container = event.target.closest(selectors.container);
		if ($container) {
			module._hideAllNotifications($container);
			module._unlockForm($container);
		}
	},
	_updateNotificationDisplay($container) {
		let $input = $container.querySelector(selectors.date_picker);
		let $notifications = $container.querySelectorAll(selectors.notification);
		let parts = $input.value.split('-');
		let reversedValue = `${parts[2]}-${parts[1]}-${parts[0]}`;
		let selectedDate = new Date(reversedValue);

		module._hideAllNotifications($container);
		let $match = Array.from($notifications).find(x => module._matchesDate(x, selectedDate));
		if ($match) {
			$match.style.display = 'block';
			module._lockForm($container);
		} else {
			module._unlockForm($container);
		}
	},
	_hideAllNotifications($container) {
		let $notifications = $container.querySelectorAll(selectors.notification);
		Array.from($notifications).forEach(x => x.style.display = 'none');
	},
	_matchesDate($notification, date) {
		let start = new Date($notification.getAttribute('data-start'));
		let end = new Date($notification.getAttribute('data-end'));

		let isAfterStart = date > start;
		let isBeforeEnd = date < end;

		return isAfterStart && isBeforeEnd;
	},
	_lockForm($container) {
		let $button = $container.querySelectorAll(selectors.book_button);
		Array.from($button).map(x => x.setAttribute('disabled', true));
	},
	_unlockForm($container) {
		let $button = $container.querySelectorAll(selectors.book_button);
		Array.from($button).map(x => x.removeAttribute('disabled'));
	},
};

export {module as Tour};