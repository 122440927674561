import Pikaday from 'pikaday';
import moment from 'moment';
import { Calendar } from '../event/calendar';

const selectors = {
	input: '.js-datepicker',
	value: '.js-datepicker-value',
};

let elements = document.querySelectorAll(selectors.input);

let module = {

	init() {
		if (elements.length) {

			for (let i = 0; i < elements.length; i++) {
				module._initDatePicker(elements[i]);
			}

		}
	},

	_initDatePicker(element) {
		let picker = new Pikaday({
			field: element,
			theme: "c-booking-calendar",
			format: 'Do MMM YYYY',
			minDate: new Date(),
			onSelect: function () {
				var date = this.getMoment().format('DD-MM-YYYY');

				element.parentNode.querySelector(selectors.value).value = date;
				element.parentNode.querySelector(selectors.value).dispatchEvent(new Event('change'));
			}
		});
	},

};

export { module as DatePicker };