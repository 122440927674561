// ************************************ //
// Every page - framework functionality //
// ************************************ //

import $ from 'jquery';

// These modules are always used
import { Nav } from 'App/nav/nav';
import { Social as SocialShare } from 'App/social/share';
import { Cookie } from 'App/cookie/cookie';
import { BackToTop } from 'App/back-to-top/back-to-top';

Nav.init();
SocialShare.init();
Cookie.init();
BackToTop.init();

// Publish resize and scroll events for other modules to use
import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));

// These modules are used often enough, or are small enough, that it's worth always loading them
import { Rte } from 'Controls/control.rte';
Rte.init();

import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { selectLink } from 'App/select-link/select-link';
import { VideoGroupLoad } from 'App/video-group/video-group';

import 'Lib/swiper-element-bundle.min';

// ********************************************************* //
// Controls - comment out any not being used by this project //
// ********************************************************* //

// Gallery Grid
import { GalleryGrid } from 'Controls/control.gallery-grid';
const $galleryGrids = $('.js-gallery-grid');

if ($galleryGrids.length) {
	GalleryGrid.init($galleryGrids);
}

// Gallery Rotator
import { GalleryRotator } from 'Controls/control.gallery-rotator';
const $galleryRotators = $('.js-gallery-rotator');

if ($galleryRotators.length) {
	GalleryRotator.init($galleryRotators);
}

// Google Maps
import { GoogleMap } from 'Controls/control.google-map';
GoogleMap.init();

// Locations List
import { LocationsList } from 'Controls/control.locations-list';
LocationsList.init();

// Logo Carousel
import { LogoCarousel } from 'Controls/control.logo-carousel';
const $logoCarousels = $('.js-logo-carousel');

if ($logoCarousels.length) {
	LogoCarousel.init($logoCarousels);
}

// Pathway Carousel
import { PathwayCarousel } from 'Controls/control.pathway-carousel';
const $pathwayCarousels = $('.js-pathway-carousel');

if ($pathwayCarousels.length) {
	PathwayCarousel.init($pathwayCarousels);
}

// Testimonials Rotator
import { TestimonialsRotator } from 'Controls/control.testimonials-rotator';
const $testimonialsRotators = $('.js-testimonials-rotator');

if ($testimonialsRotators.length) {
	TestimonialsRotator.init($testimonialsRotators);
}

// Latest Insights
import { LatestInsights } from 'Controls/control.latest-insights';
const $latestInsights = $('.js-latest-insights');

if ($latestInsights.length) {
	LatestInsights.init($latestInsights);
}

// Expand and Collapse

if (document.querySelector('.js-expand-collapse')) {
	expandCollapse.init();
}

// Select Link
if (document.querySelector('.js-select-link')) {
	selectLink.init();
}

// Video Group
if (document.querySelector('.js-video-group')) {
	VideoGroupLoad.init();
}

// Booking Form
import { NumberInput } from 'App/form/number-input';
NumberInput.init();

import { DatePicker } from 'App/form/date-picker';
DatePicker.init();

import { Tour } from 'App/form/tour';
Tour.init();

// Media Gallery
import { MediaGallery } from 'Controls/control.media-gallery';
MediaGallery.init();

// Trade Media
import { TradeMedia } from 'Controls/control.trade-media';
TradeMedia.init();

//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

// Modal
if (document.querySelector('.js-modal')) {
	import(/* webpackChunkName: "Modal" */ 'App/modal/modal').then(({ modal }) => {
		modal.init({
			onShow: function ($modal) {
				var $rotators = $modal.find('.js-rotator');
				var thisFlick;
				$rotators.each(function (i, el) {
					thisFlick = $(el).data('flickity-instance');

					if (thisFlick) {
						thisFlick.resize();
					}
				});
			}
		});
	});
}

// Scroll Appear
if (document.querySelector('.js-scroll-appear')) {
	import(/* webpackChunkName: "ScrollAppear" */ 'App/scroll-appear/scroll-appear').then(({ scrollAppear }) => {
		scrollAppear.init();
	});
}

// Umbraco Forms
const $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Video Player
if (document.querySelector('.js-video-player')) {
	import(/* webpackChunkName: "VideoPlayer" */ 'App/video/video.player').then(({ VideoPlayer }) => {
		VideoPlayer.init();
	});
}

// Video Gallery
if (document.querySelector('.js-video-gallery')) {
	import(/* webpackChunkName: "VideoGallery" */ 'App/video/gallery').then(({ videoGallery }) => {
		videoGallery.init();
	});
}

// Event calendar add
if (document.querySelector('.js-calendar-link')) {
	import(/* webpackChunkName: "Calendar" */ 'App/event/calendar').then(({ Calendar }) => {
		Calendar.init();
	});
}

//Tooltip
if (document.querySelector('.js-tooltip')) {
	import(/* webpackChunkName: "Tooltips" */ 'App/tooltip/tooltip').then(({ Tooltip }) => {
		Tooltip.init();
	});
}



// Showcase banner scroller

$("#ScrollDown").on("click", function () {

	$('html,body').animate({
		scrollTop: $('#ScrollDown').offset().top
	}, 'slow');
})

// Cookie for the notification bar

import Cookies from 'js-cookie'

function heightAdjust() {
	var HeaderHeight = $('.js-header').outerHeight();
	var HeroHeight = window.innerHeight - HeaderHeight;

	$('.c-header__spacer').css('height', HeaderHeight);
	$('.js-home-hero').css('height', HeroHeight);
};

window.addEventListener('resize', function () {

	heightAdjust();

});

window.addEventListener('load', function () {

	heightAdjust();

	$(".js-header-notification__close").on("click", function () {

		$(".js-header-notification").hide();
		Cookies.set('notification', true, { expires: 60 });

		heightAdjust();
	});

	if (!Cookies.get("notification")) {
		$(".js-header-notification").show();

		heightAdjust();
	}
});

// Main scroll top

function fixMain() {

	$('main').each(function () {
		var $this = $(this);
		var offset = $this.offset().top;
		var scrollTop = $(window).scrollTop();

		if (scrollTop > offset) {
			$this.addClass('fixed');
		} else {
			$this.removeClass('fixed');
		}
	});
}

function stickySidenav() {
	//$('.js-sticky-sidenav').each(function () {
	//	var $this = $(this);
	//	var offset = $this.offset().top;
	//	var scrollTop = $(window).scrollTop();

	//	if (scrollTop > offset) {
	//		$this.addClass('fixed');
	//	} else {
	//		$this.removeClass('fixed');
	//	}
	//});
}

window.addEventListener('scroll', function () {
	fixMain();
	stickySidenav();
});

