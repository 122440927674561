/* Controls/control.trade-media */

const selectors = {
	container: '.js-trade-media',
	trigger: '.js-trade-media__trigger',
	target: '.js-trade-media__target',
};

let $containers = null;

let module = {
	init() {
		$containers = document.querySelectorAll(selectors.container);
		if ($containers.length) {
			module._initEvents();
		}
	},
	_initEvents() {
		for (let i = 0; i < $containers.length; i++) {
			let $container = $containers[i];
			$container.addEventListener('click', module._handleClickEvent);
		}
	},
	_handleClickEvent(event) {
		let $trigger = event.target.closest(selectors.trigger);
		if ($trigger) {
			if ($trigger.getAttribute('aria-current') === 'false') {
				let $container = event.target.closest(selectors.container);
				let filter = $trigger.getAttribute('data-type');

				let $triggers = $container.querySelectorAll(selectors.trigger);
				for (let i = 0; i < $triggers.length; i++) {
					if ($triggers[i].getAttribute('aria-current') === 'true') {
						$triggers[i].setAttribute('aria-current', false);
					}
				}
				$trigger.setAttribute('aria-current', true);

				module._updateItemsDisplayed($container, filter);
			}
		}
	},
	_updateItemsDisplayed($container, filter) {
		let $targets = Array.from($container.querySelectorAll(selectors.target));
		$targets.map($target => {
			let type = $target.getAttribute('data-type');
			if (type === filter || filter === 'all') {
				$target.setAttribute('aria-expanded', true);
			} else {
				$target.setAttribute('aria-expanded', false);
			}
		});
	}
};

export {module as TradeMedia};