/* Controls/control.gallery-grid */

import $ from 'jquery';
import 'magnific-popup';

let $galleryGrids;
const toggleClass = 'show-all';

var GalleryGrid = {
	init: function ($elms) {
		$galleryGrids = $elms;

		GalleryGrid._initEvents();

		for (let i = 0; i < $galleryGrids.length; i++) {
			GalleryGrid._initLightbox($galleryGrids.eq(i));
		}
	},

	_initEvents: function () {
		$galleryGrids.on('click', '.js-gallery-grid__toggle', GalleryGrid._toggleList);
	},

	_toggleList: function (event) {
		event.preventDefault();

		const $btn = $(this);
		const $thisList = $btn.closest('.js-gallery-grid');

		if ($thisList) {
			$thisList.toggleClass(toggleClass);

			if ($thisList.hasClass(toggleClass)) {
				$btn.text('Show less');
			} else {
				$btn.text('Show more');
			}
		}
	},

	_initLightbox: function ($galleryGrid) {
		$galleryGrid.magnificPopup({
			delegate: '.js-gallery-grid__image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	}
};

export { GalleryGrid };
