/* Expand/Collapse v2.0.1 */

import $ from 'jquery';
import Velocity from 'velocity-animate';
import { activate } from 'Util/activate';
import { publish, subscribe } from 'Util/pubsub';

const selectors = {
	item: '.js-expand-collapse',

	toggle: '.js-expand-collapse__toggle, .js-expand-collapse__trigger',
	open: '.js-expand-collapse__open',
	close: '.js-expand-collapse__close',

	body: '.js-expand-collapse__item-body',

	opened: '.js-expand-collapse--start-expanded'
};
selectors.trigger = [selectors.toggle, selectors.open, selectors.close].join(', ');
Object.freeze(selectors);

const classes = Object.freeze({
	allowAnimations: 'allow-animations'
});

const dataAttributes = Object.freeze({
	autofocusId: 'data-expand-collapse-autofocus',
	// Used to account for CSS animation delaying when an element becomes focusable
	autofocusDelay: 'data-expand-collapse-autofocus-delay',

	// No more than one item in a set can be opened at a time
	// Items can belong to multiple sets, with set names separated
	// by the "setSeparator" string declared below
	set: 'data-expand-collapse-set',

	openedText: 'data-expand-collapse-opened-text',
	closedText: 'data-expand-collapse-closed-text',

	animation: 'data-expand-collapse-animation',

	useHidden: 'data-expand-collapse-use-hidden',
	useCurrent: 'data-expand-collapse-use-current',
});

const setSeparator = ' ';

const pubsubEvents = Object.freeze({
	open: '/expandcollapse/open',
	opened: '/expandcollapse/opened',

	close: '/expandcollapse/close',
	closed: '/expandcollapse/closed',

	closeSet: '/expandcollapse/close/set', // Close a set
	setClosed: '/expandcollapse/closed/set' // A set has been closed
});

const States = Object.freeze({
	INITIAL: 'initial',
	OPENED: 'opened',
	CLOSED: 'closed'
});

const stateAttributes = Object.freeze({
	expanded: 'aria-expanded',
	hidden: 'aria-hidden',

	current: 'aria-current'
});

let currentSetActions = [];

const module = {
	init() {
		module._initEvents();
		module._initSubscriptions();
		module._loadOpened();
	},

	_initEvents() {
		$(document).on(activate.event, selectors.trigger, activate(module._processTriggerClick));
	},

	_initSubscriptions() {
		subscribe(pubsubEvents.open, module._open);
		subscribe(pubsubEvents.close, module._close);
		subscribe(pubsubEvents.closeSet, module._closeSet);
	},

	_processTriggerClick(e) {
		// The expand/collapse trigger for a single item

		e.preventDefault();

		const $trigger = $(e.target).closest(selectors.trigger);
		const $items = module._getTriggerItems($trigger);

		$items.each(function (index, item) {
			const $item = $(item);

			if ($trigger.is(selectors.toggle)) {
				module._toggleItem($item);
			} else if ($trigger.is(selectors.open)) {
				module._setState($item, States.OPENED);
			} else if ($trigger.is(selectors.close)) {
				module._setState($item, States.CLOSED);
			}
		});

		// Clear current actions
		currentSetActions = [];
	},

	_toggleItem($item) {
		// Toggle an item's state between opened and closed

		// Detect the current state so we can infer the action from that
		let state = module._getState($item);

		if (state === States.CLOSED || state === States.INITIAL) {
			// Currently closed, so open it
			// Treat unspecified initial states as closed
			module._setState($item, States.OPENED);
		} else if (state === States.OPENED) {
			// Currently opened, so close it
			module._setState($item, States.CLOSED);
		}
	},

	_getTriggerItems($trigger) {
		let $items = $();
		let targets = $trigger.attr('aria-controls');

		// If there is an aria-controls attribute, use that
		if (targets) {
			targets = targets.split(' ');
			for (let i = 0; i < targets.length; i++) {
				const target = targets[i];
				const $item = $('#' + target);
				$items = $items.add($item);
			}
		}

		// Otherwise, use HTML structure
		if (!($items && $items.length)) {
			$items = $trigger.closest(selectors.item);
		}

		return $items;
	},

	_getItemTriggers($item) {
		// Triggers using the aria-controls attribute
		let $idTriggers;
		const id = $item.attr('id');
		if (id) {
			$idTriggers = $(selectors.trigger);

			$idTriggers = $idTriggers.filter(function (index, trigger) {
				const $trigger = $(trigger);
				let targets = $trigger.attr('aria-controls');

				if (targets) {
					targets = targets.split(' ');
					for (let i = 0; i < targets.length; i++) {
						const target = targets[i];

						if (target === id) {
							return true;
						}
					}
				}

				return false;
			});
		} else {
			$idTriggers = $();
		}

		// Triggers using HTML structure
		const $childTriggers = $item.find(selectors.trigger).filter(function () {
			return $(this).closest(selectors.item).is($item);
		});

		const $triggers = $idTriggers.add($childTriggers);
		const triggerGroups = {
			open: [],
			close: [],
			toggle: []
		};

		triggerGroups.all = $triggers;
		triggerGroups.open = $triggers.filter(selectors.open);
		triggerGroups.close = $triggers.filter(selectors.close);
		triggerGroups.toggle = $triggers.filter(selectors.toggle);

		return triggerGroups;
	},

	_getState($item) {
		const usingHidden = $item.attr(dataAttributes.useHidden);

		let stateAttr = stateAttributes.expanded;
		let closedVal = 'false';
		let openedVal = 'true';

		if (usingHidden) {
			stateAttr = stateAttributes.hidden;
			closedVal = 'true';
			openedVal = 'false';
		}

		if ($item.attr(stateAttr) === closedVal) {
			// Closed
			return States.CLOSED;
		} else if ($item.attr(stateAttr) === openedVal) {
			// Opened
			return States.OPENED;
		} else {
			// Default/Initial
			return States.INITIAL;
		}
	},

	_open($item) {
		module._setState($item, States.OPENED);
	},

	_close($item) {
		module._setState($item, States.CLOSED);
	},

	_setState($item, state) {
		const priorState = module._getState($item);

		if (priorState === state) {
			return;
		}

		const $body = $item.find(selectors.body);

		let expandedAttr = stateAttributes.expanded;
		let openedVal = 'true';
		let closedVal = 'false';

		const useHidden = $item.attr(dataAttributes.useHidden);
		if (useHidden) {
			expandedAttr = stateAttributes.hidden;
			openedVal = 'false';
			closedVal = 'true';
		}

		if (state === States.OPENED) {
			// Close the whole set first
			module._closeSet($item);

			// Set to opened state
			$item.attr(expandedAttr, openedVal);

			if ($item.attr(dataAttributes.animation) === 'slideDown') {
				Velocity($body, 'slideDown', { duration: 250 });
			}

			module._setStateTriggers($item, state);

			module._processOpen($item);
			publish(pubsubEvents.opened, [$item, $item.attr(dataAttributes.set)]);
		} else if (state === States.CLOSED) {
			// Set to closed state
			$item.attr(expandedAttr, closedVal);

			if ($item.attr(dataAttributes.animation) === 'slideDown') {
				Velocity($body, 'slideUp', { duration: 250 });
			}

			module._setStateTriggers($item, state);

			module._processClose($item);
			publish(pubsubEvents.closed, [$item, $item.attr(dataAttributes.set)]);
		} else if (state === States.INITIAL) {
			// Remove state signifiers
			$item.removeAttr(expandedAttr);

			module._setStateTriggers($item, state);
		}

		// The first time an item's state is updated, allow animations
		if ($item.hasClass(classes.allowAnimations) === false) {
			$item.addClass(classes.allowAnimations);
		}
	},

	_setStateTriggers($item, state) {
		const $triggers = module._getItemTriggers($item).all;

		$triggers.each(module._setStateTrigger(state));
	},

	_setStateTrigger(state) {
		return function (i, trigger) {
			const $trigger = $(trigger);

			let stateAttr = stateAttributes.expanded;
			const useCurrent = $trigger.attr(dataAttributes.useCurrent);

			if (useCurrent) {
				stateAttr = stateAttributes.current;
			}

			if (state === States.OPENED) {
				$trigger.attr(stateAttr, 'true');
			} else if (state === States.CLOSED) {
				$trigger.attr(stateAttr, 'false');
			} else if (state === States.INITIAL) {
				$trigger.removeAttr(stateAttr);
			}
		};
	},

	_closeSet(set) {
		let $item;

		if (set && set.jquery) {
			// If a jQuery object was passed through
			$item = set;
			set = $item.attr(dataAttributes.set);
		}

		if (!set) {
			return;
		}

		set = set.split(setSeparator);
		if (set.length > 1) {
			for (let i = 0; i < set.length; i++) {
				module._closeSet(set[i]);
			}
			return;
		} else {
			set = set[0].trim();
		}

		// if current action is processing set
		// then ignore otherwise add to the list of sets processed
		if (currentSetActions.indexOf(set) !== -1) {
			return;
		} else {
			currentSetActions.push(set);
		}

		publish(pubsubEvents.setClosed, [set]);

		let $setItems = $('[' + dataAttributes.set + ']').filter(module._isInSet(set));
		if ($item && $item.length) {
			// If an item has been passed through, it is being opened,
			// so only close other items in this set
			$setItems = $setItems.not($item);
		}

		for (let i = 0; i < $setItems.length; i++) {
			const $setItem = $setItems.eq(i);
			if (module._getState($setItem) === States.OPENED) {
				module._setState($setItem, States.CLOSED);
			}
		}
	},

	_isInSet(set) {
		return function (i, el) {
			const $item = $(el);
			let itemSet = $item.attr(dataAttributes.set);

			itemSet = itemSet.split(setSeparator);

			return itemSet.indexOf(set) !== -1;
		};
	},

	_processOpen($item) {
		// Run when an item enters the opened state

		const autofocusId = $item.attr(dataAttributes.autofocusId);
		if (autofocusId) {
			const autofocusDelay = parseInt($item.attr(dataAttributes.autofocusDelay), 10);
			const $autofocusTarget = $('#' + autofocusId);

			if (autofocusDelay) {
				window.setTimeout(function () {
					$autofocusTarget[0].focus();
				}, autofocusDelay);
			} else {
				$autofocusTarget[0].focus();
			}
		}

		window.setTimeout(function () {
			$item.removeAttr('aria-live');
		}, 100);
	},

	_processClose($item) {
		// Run when an item enters the closed state

		window.setTimeout(function () {
			$item.attr('aria-live', 'polite');
		}, 100);
	},

	_loadOpened() {

		if (window.innerWidth > 1024) {

			$(selectors.opened).trigger("click");

		}
	}
};

export { module as expandCollapse };
