/* Controls/control.media-gallery */

const selectors = {
	container: '.js-media-gallery',
	video: '.js-media-gallery__video',
};

let $containers = null;

let module = {
	init() {
		$containers = document.querySelectorAll(selectors.container);
		if ($containers.length) {
			module._initEvents();
		}
	},
	_initEvents() {
		for (let i = 0; i < $containers.length; i++) {
			let $container = $containers[i];
			$container.addEventListener('click', module._handleClickEvent);
		}
	},
	_handleClickEvent(event) {
		let $target = event.target.closest(selectors.video);
		if ($target) {
			if ($target.getAttribute('aria-current') === 'true') {
				module._stopVideo($target);
			} else {
				module._stopAllVideos();
				module._startVideo($target);
			}
		}
	},
	_stopVideo($target) {
		let $video = $target.querySelector('video');
		$target.setAttribute('aria-current', false);
		$video.pause();
		$video.currentTime = 0;
		//$video.removeAttribute('controls');
	},
	_startVideo($target) {
		let $video = $target.querySelector('video');
		$target.setAttribute('aria-current', true);
		$video.play();
		//$video.setAttribute('controls', true);
	},
	_stopAllVideos() {
		let $currentVideos = document.querySelectorAll(selectors.video + '[aria-current=true]');
		if ($currentVideos.length) {
			for (let i = 0; i < $currentVideos.length; i++) {
				let $target = $currentVideos[i];
				module._stopVideo($target);
			}
		}
	}
};

export { module as MediaGallery };