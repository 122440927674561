/* app/ui/nav/nav */

import $ from 'jquery';
import { HeaderSticky } from 'App/nav/sticky';
import { publish, subscribe } from 'Util/pubsub';

const selectors = {
	// sticky
	header: '.js-header',
	notification: '.js-header-notification',
	// navigation
	menu: '.js-nav__menu',
	booking: '.js-nav__booking',
	bookingButton: '.js-nav__booking-button',
	menuButton: '.c-header__mobile-button.js-expand-collapse__toggle',
	menuLink: '.c-header-nav__primary-link.js-expand-collapse__open',
	flyout: '#nav-flyout',
	topLevel: '.js-nav-toplevel',

	secondLevel: '.c-header-nav__flyout-container-level-2',
	thirdLevel: '.c-header-nav__flyout-container-level-3',
};

const classes = {
	headerUp: 'is-up',
	headerDown: 'is-down',
	headerSticky: 'is-sticky',
	headerNavOpen: 'is-open',
	allowAnimations: 'allow-animations',
	keepOpen: 'keep-open',
};

const pubsubEvents = Object.freeze({
	opened: '/expandcollapse/opened',
	closed: '/expandcollapse/closed',
});

let $elements = {
	$menu: null,
	$menuButton: null,
	$menuLink: null,
	$booking: null,
	$bookingButton: null,
	$flyout: null,
	$toplevel: null,
};

var module = {
	init: function () {
		module._initElements();
		module._initEvents();
		module._initSubscriptions();

		HeaderSticky.init(selectors, classes);
	},

	_initElements: function () {
		$elements.$menu = $(selectors.menu);
		$elements.$menuButton = $(selectors.menuButton);
		$elements.$menuLink = $(selectors.menuLink);
		$elements.$booking = $(selectors.booking);
		$elements.$bookingButton = $(selectors.bookingButton);
		$elements.$toplevel = $(selectors.topLevel);
		$elements.$flyout = $(selectors.flyout);
	},

	_initEvents: function () {
		$('body').on('click', module._handleBodyClick);

		$elements.$menuButton.on('click', module._closeOtherMenu);
		$elements.$menuLink.on('click', module._closeOtherMenu);
		$elements.$bookingButton.on('click', module._closeOtherMenu);
		$elements.$toplevel.on('click', module._closeThirdLevel);

		$elements.$menuButton.on('click', module._keepHeader);
		$elements.$menuLink.on('click', module._keepHeader);
		$elements.$bookingButton.on('click', module._keepHeader);

		$('.js-navs-close').on('click', module._closeEverything);
	},

	_initSubscriptions() {
		subscribe(pubsubEvents.opened, module._updateCurrentLevel);
		subscribe(pubsubEvents.closed, module._updateCurrentLevel);
	},

	_handleBodyClick: function (e) {
		let $target = $(e.target);
		let isWithinMenu = $target.closest(selectors.menu).length > 0 || $target.closest(selectors.booking).length > 0 || $target.closest(selectors.bookingButton).length > 0;

		if (!isWithinMenu) {
			module._closeExpandedItems($elements.$menu);
			module._closeExpandedItems($elements.$booking);
			$(selectors.header).removeClass(classes.keepOpen);
		}
	},

	_closeEverything: function () {
		module._closeExpandedItems($elements.$menu);
		module._closeExpandedItems($elements.$booking);
		$(selectors.header).removeClass(classes.keepOpen);
	},

	_closeExpandedItems: function ($container) {
		let $expandedItem = $container.find('[aria-expanded="true"]');
		if ($expandedItem.length) {
			$expandedItem.attr('aria-expanded', false);
			$expandedItem.addClass(classes.allowAnimations);
			$(selectors.header).removeClass(classes.keepOpen);
		}
	},

	_closeOtherMenu: function (e) {
		let $target = $(e.target);
		let isMenu = $target.closest(selectors.menu).length > 0;
		let isBooking = $target.closest(selectors.bookingButton).length > 0;

		if (isMenu) {
			module._closeExpandedItems($elements.$booking);
		}

		if (isBooking) {
			module._closeExpandedItems($elements.$menu);
		}
	},

	_closeThirdLevel: function (e) {
		let $trigger = $(e.target);
		let isToggle = $trigger.is(".js-expand-collapse__open");

		if (isToggle) {
			let $thirdLevel = $elements.$menu.find('[data-expand-collapse-set="third"]');

			if ($thirdLevel.length) {
				$thirdLevel.attr('aria-expanded', false);
			}
		}
	},

	_updateCurrentLevel: function (e) {

		let $secondLevel = $(selectors.secondLevel);
		let $thirdLevel = $(selectors.thirdLevel);

		if ($thirdLevel.filter('[aria-expanded="true"]').length > 0) {
			$elements.$flyout.attr('data-current-level', 3);
		}
		else if ($secondLevel.filter('[aria-expanded="true"]').length > 0) {
			$elements.$flyout.attr('data-current-level', 2);
		} else {
			$elements.$flyout.attr('data-current-level', 1);
		}

	},

	_keepHeader: function () {

		if (!$(selectors.header).hasClass(classes.keepOpen)) {
			$(selectors.header).addClass(classes.keepOpen);
		}
		
	},
};

var Nav = {
	init: module.init
};

export { Nav };
